function gnav() {
  const gnav = document.getElementById('gnav');

  const gnavBtn = document.getElementById('gnav-btn');
  const menuTrigger = document.querySelector('.menu-trigger');

  const fadeinItem = document.querySelectorAll('.rollover span');

  gnavBtn.addEventListener('click', (e) => {

    gnav.classList.toggle('active');
    menuTrigger.classList.toggle('active');
    gnavBtn.classList.toggle('active');

    fadeinItem.forEach(fadeinL => {
      if (gnav.classList.contains('active')) {
        fadeinL.classList.add('on');
      } else {
        fadeinL.classList.remove('on');
      }
    });

    e.preventDefault();
  });
}
window.addEventListener("load", gnav);