function check_input() {
  const nameText = document.getElementById('name');
  const commentText = document.getElementById('comment');

  if (nameText) {
    nameText.addEventListener('keyup', () => {
      if (nameText.value.length > 6) {
        nameText.blur();
      }
    });
  } else if (commentText) {
    commentText.addEventListener('keyup', () => {
      if (commentText.value.length > 20) {
        commentText.blur();
      }
    });
  }
  
}
window.addEventListener("load", check_input);