function final_stage() {
  const bestImgBtns = document.querySelectorAll('.best-img-btn');

  const parent = document.getElementById('god-select');
  const just7G = document.getElementById('just-seven-gods') != null;

  const over_alert = document.getElementById('over_gods');
  const short_alert = document.getElementById('short_gods');
  
  const sub = document.getElementById('submit');

  bestImgBtns.forEach(bib => {
    bib.addEventListener('click', () => {

      // bibのvalue: best.idをパースして取得
      const bestId = JSON.parse(bib.value);
      
      // hidden_fieldのvalueにフォームで送信するためのbestIdをセット
      const currentBest = document.getElementById('current-best');
      currentBest.value = bestId;

      // godカードを表示するためのinputタグ生成
      let child = document.createElement('input');
      // bibのsrcを取得
      let cardAdd = bib.getAttribute('src');

      // godカード７枚制限
      let god_count = n + 1;
      if (god_count <= 7) {
        // クリックしたbestカードを非表示
        bib.style.display = "none";
        // bestsから選んだカードからgodカードを生成(呼び出し)
        addGod(child, cardAdd, bestId, bib);
      } else {
        over_alert.style.display = "block";
      }

      // bestカードクリックでflashメッセージ非表示
      short_alert.style.display = "none";

      textAdd();
    });
  });

  let n = 0;

  // bestsから選んだカードからgodカードを生成
  function addGod(child, cardAdd, bestId, bib) {
    n++;
    child.type = "image";
    child.src = cardAdd;
    child.value = bestId;
    child.alt = "myGod" + n;
    child.classList = "final-check-cardsize";
    child = parent.appendChild(child);

    child.addEventListener('click', () => {

      n--;

      // child(godの画像サブミット)のvalue: god.idをパースして取得
      const godId = JSON.parse(child.value);

      // hidden_fieldのvalueにフォームで送信するためのgodIdをセット
      const currentGod = document.getElementById('current-god');
      currentGod.value = godId;

      // クリックしたgodカードを非表示
      child.style.display = "none";
      // bestカードを表示に戻す
      bib.style.display = "block";

      // godカードクリックでflashメッセージ非表示
      over_alert.style.display = "none";
      short_alert.style.display = "none";

      textRemove();
    });
  };

  // godカード７枚未満の場合に"画像を作成"を押すとflashメッセージ表示
  if (sub) {
    sub.addEventListener('click', (e) => {

      if (!just7G && n < 7) {
        short_alert.style.display = "block";
        e.preventDefault();
      }
    });
  }

  // テキスト関係の表示、非表示切り替え
  const bottomText = document.getElementById('bottom-select-p');

  function textAdd() {
    if (n >= 1) {
      bottomText.style.display = "block";
    }
  };

  function textRemove() {
    if (n == 0) {
      bottomText.style.display = "none";
    }
  };

}
window.addEventListener("load", final_stage);