function select_options() {
  const sceneSelect = document.getElementById('scene-select');
  const anotherSceneForm = document.getElementById('another_scene_form');
  const anotherScene = document.getElementById('another_scene');

  const selectSceneBlank = document.getElementById('select_scene_blank');
  const anotherSceneBlank = document.getElementById('another_scene_blank');
  const anotherSceneShort = document.getElementById('another_scene_short');
  const replayBtn01 = document.getElementById('replay_btn_01');
  const replayBtn02 = document.getElementById('replay_btn_02');

  if (sceneSelect) {
    const sceneOptions = sceneSelect.options;
    const scene5 = sceneOptions[5];
    const scene2 = sceneOptions[2];
    const scene9 = sceneOptions[9];
    const scene0 = sceneOptions[0];
    
    // 「仕事」を「家族」の上に表示
    scene2.parentNode.insertBefore(scene5, scene2);

    // シーン選択「その他(自由記述)」の表示切替
    if (scene9.selected) {
      anotherSceneForm.style.display = "flex";
    } else {
      anotherSceneForm.style.display = "none";
    }

    sceneSelect.addEventListener('change', () => {
      if (scene9.selected) {
        anotherSceneForm.style.display = "flex";

        if (replayBtn01) {
          replayBtn01.addEventListener('click', (e) => {
            replayAlert(e);
          });
          replayBtn02.addEventListener('click', (e) => {
            replayAlert(e);
          });
        }

      } else {
        anotherSceneForm.style.display = "none";
      }

      if (scene0.selected) {
        selectSceneBlank.style.display = "block";
      } else {
        selectSceneBlank.style.display = "none";
      }
    });

    if (replayBtn01) {
      replayBtn01.addEventListener('click', (e) => {
        if (scene0.selected) {
          selectSceneBlank.style.display = "block";
          e.preventDefault();
        }
      });
      replayBtn02.addEventListener('click', (e) => {
        if (scene0.selected) {
          selectSceneBlank.style.display = "block";
          e.preventDefault();
        }
      });
    }
  }

  function replayAlert(e) {
    if (anotherScene.value == "" && anotherSceneForm.style.display == "flex") {
      anotherSceneBlank.style.display = "block";
      anotherSceneShort.style.display = "none";
      e.preventDefault();
    } else if (anotherScene.value.length > 15 && anotherSceneForm.style.display == "flex") {
      anotherSceneShort.style.display = "block";
      anotherSceneBlank.style.display = "none";
      e.preventDefault();
    }
  };


  
  const ageSelect = document.getElementById('age_select');

  if (ageSelect) {
    ageSelect.addEventListener('focus', (age) => {
      if (age.currentTarget.options.length >= 11) {
        age.currentTarget.size = '8';
      }
    });
    
    ageSelect.addEventListener('blur', (age) => {
      age.currentTarget.size = '1';
    });
    
    ageSelect.addEventListener('change', (age) => {
      age.currentTarget.blur();
    });
  }

}
window.addEventListener("load", select_options);