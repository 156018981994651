function job_chart() {
  var ctx = document.getElementById("myChart");

  const modalDetail = document.querySelectorAll('.modal-detail');
  const modalContents = document.querySelectorAll('.modal-contents');

  const modalDetail_A = document.querySelector('.modal-detail-A');
  const modalDetail_B = document.querySelector('.modal-detail-B');
  const modalDetail_C = document.querySelector('.modal-detail-C');
  const modalDetail_D = document.querySelector('.modal-detail-D');
  const modalDetail_E = document.querySelector('.modal-detail-E');
  const modalDetail_F = document.querySelector('.modal-detail-F');
  const modalDetail_G = document.querySelector('.modal-detail-G');
  const modalDetail_H = document.querySelector('.modal-detail-H');
  const modalDetail_I = document.querySelector('.modal-detail-I');

  const userName = document.getElementById('user_name');
  if (userName) {
    userNameValue = userName.value;
  }

  const jobType = document.getElementById('job_type');
  if (jobType) {

    jobTypeValue = jobType.value;
    const jobTypeArr = JSON.parse(jobTypeValue);

    var myChart = new Chart(ctx, {
      type: 'radar',
      data: {
        labels: [["A", "専門"], ["B", "組織"], ["C", "独自"], ["D", "安心,", "安定"], ["E", "創造"], ["F", "社会貢献"], ["G", "挑戦,", "問題解決"], ["H", "自然体"], ["I", "伝達"]],
        datasets: 
        [
            {
            label: '',
            backgroundColor: 'rgba(122,205,129,0.5)',
            borderColor: 'rgba(122,255,129,0.5)',
            data: jobTypeArr
            }
        ]
      },
      options: {
          maintainAspectRatio: false,
          title: {                 // タイトル
              display: true,
              fontSize: 24,
              fontColor: '#212529',
              text: [[`${userNameValue}`], ["仕事の価値観の源泉"]]
          },
          legend: {
              position: 'hidden'   // 凡例の表示位置
          },
          scale: {
              pointLabels: {       // 軸のラベル（"国語"など）
                  fontSize: 18,         // 文字の大きさ
              },
              ticks: {
                  suggestedMin: 0,
                  suggestedMax: 10
              }
          }
      }
    });

  }



  if (ctx) {
    
    ctx.addEventListener('click', (e) => {
      var helpers = Chart.helpers;
      var scale = myChart.scale;
      var opts = scale.options;
      var tickOpts = opts.ticks;

      // Position of click relative to canvas.
      var mouseX = e.offsetX;
      var mouseY = e.offsetY;

      var labelPadding = 5; // number pixels to expand label bounding box by

      // get the label render position
      // calcs taken from drawPointLabels() in scale.radialLinear.js
      var tickBackdropHeight = (tickOpts.display && opts.display) ?
          helpers.valueOrDefault(tickOpts.fontSize, Chart.defaults.global.defaultFontSize)
          + 5: 0;
      var outerDistance = scale.getDistanceFromCenterForValue(opts.ticks.reverse ? scale.min : scale.max);
      for (var i = 0; i < scale.pointLabels.length; i++) {
        // Extra spacing for top value due to axis labels
        var extra = (i === 0 ? tickBackdropHeight / 2 : 0);
        var pointLabelPosition = scale.getPointPosition(i, outerDistance + extra + 5);

        // get label size info.
        // TODO fix width=0 calc in Brave?
        // https://github.com/brave/brave-browser/issues/1738
        var plSize = scale._pointLabelSizes[i];

        // get label textAlign info
        var angleRadians = scale.getIndexAngle(i);
        var angle = helpers.toDegrees(angleRadians);
        var textAlign = 'right';
        if (angle == 0 || angle == 180) {
            textAlign = 'center';
        } else if (angle < 180) {
            textAlign = 'left';
        }

        // get label vertical offset info
        // also from drawPointLabels() calcs
        var verticalTextOffset = 0;
        if (angle === 90 || angle === 270) {
            verticalTextOffset = plSize.h / 2;
        } else if (angle > 270 || angle < 90) {
            verticalTextOffset = plSize.h;
        }

        // Calculate bounding box based on textAlign
        var labelTop = pointLabelPosition.y - verticalTextOffset - labelPadding;
        var labelHeight = 2*labelPadding + plSize.h;
        var labelBottom = labelTop + labelHeight;

        var labelWidth = plSize.w + 2*labelPadding;
        var labelLeft;
        switch (textAlign) {
        case 'center':
          var labelLeft = pointLabelPosition.x - labelWidth/2;
          break;
        case 'left':
          var labelLeft = pointLabelPosition.x - labelPadding;

          break;
        case 'right':
          var labelLeft = pointLabelPosition.x - labelWidth + labelPadding;
          break;
        default:
          console.log('ERROR: unknown textAlign '+textAlign);
        }
        var labelRight = labelLeft + labelWidth;

        // compare to the current click
        if (mouseX >= labelLeft && mouseX <= labelRight && mouseY <= labelBottom && mouseY >= labelTop) {
          // alert(scale.pointLabels[i]+' clicked');
          // console.log(scale.pointLabels[i][0]);

          modalDetail.forEach(md => {
            if (md.classList.contains('show')) {
              md.classList.remove('show');
            }
          });

          let category = scale.pointLabels[i][0];
          switch (category) {
            case 'A':
              modalDetail_A.classList.add('show');
              break;
            case 'B':
              modalDetail_B.classList.add('show');
              break;
            case 'C':
              modalDetail_C.classList.add('show');
              break;
            case 'D':
              modalDetail_D.classList.add('show');
              break;
            case 'E':
              modalDetail_E.classList.add('show');
              break;
            case 'F':
              modalDetail_F.classList.add('show');
              break;
            case 'G':
              modalDetail_G.classList.add('show');
              break;
            case 'H':
              modalDetail_H.classList.add('show');
              break;
            case 'I':
              modalDetail_I.classList.add('show');
              break;
          }

          // Break loop to prevent multiple clicks, if they overlap we take the first one.
          break;

        } else {
          modalDetail.forEach(md => {
            if (md.classList.contains('show')) {
              md.classList.remove('show');
            }
          });
        }

      }
    });

  }

  if (modalContents) {
    modalContents.forEach(mc => {
      mc.addEventListener('click', () => {
        modalDetail.forEach(md => {
          if (md.classList.contains('show')) {
            md.classList.remove('show');
          }
        });
      });
    });
  }

}
window.addEventListener("load", job_chart);