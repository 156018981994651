function mypage_menu () {
  window.addEventListener('scroll', () => {

    const myPageHeader = document.getElementById('mypage-header');

    if (myPageHeader) {
      myPageHeader.classList.toggle('menu-on', window.scrollY > 88);
    }
  });
}
window.addEventListener("load", mypage_menu);