function image_slide () {
  const cardWrap = document.getElementById('card-wrap');
  const imgUrl = document.getElementById('images_url');
  const backBtn = document.getElementById('back-btn');
  const nextBtn = document.getElementById('next-btn');

  const slideSceneName1 = document.getElementById('slide_scene_name_1');
  const slideSceneName2 = document.getElementById('slide_scene_name_2');
  const sceneNames = document.getElementById('scene_names');
  const shareUserName = document.getElementById('share_user_name');

  const imgDownload = document.getElementById('img_download');

  const imgDate = document.getElementById('imgs_date');
  const SlideimgDate = document.getElementById('slide_img_date');

  let num = 0;

  if (backBtn && num == 0) {
    backBtn.classList.add('preparation');
  }

  if (nextBtn) {
    nextBtn.addEventListener('click', () => {
      if (num + 1 < cardWrap.children.length) {
        let child = cardWrap.children[num + 1];
  
        child.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });

        backBtn.classList.remove('preparation');
  
        num++;

        if (num + 1 >= cardWrap.children.length) {
          nextBtn.classList.add('preparation');
        }

        slideOption();

      } else {
        return;
      }
    });
  }

  if (backBtn) {
    backBtn.addEventListener('click', () => {
      if (num - 1 >= 0) {
        let child = cardWrap.children[num - 1];
  
        child.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });

        nextBtn.classList.remove('preparation');
  
        num--;

        if (num == 0) {
          backBtn.classList.add('preparation');
        }

        slideOption();

      } else {
        return;
      }
    });
  }


  // スマホ版スクロールで表示中の画像をシェア
  if (cardWrap) {
    let cardWrapWidth = cardWrap.offsetWidth;

    cardWrap.addEventListener('scroll', () => {
      let getLeftPosition = cardWrap.scrollLeft;

      switch (getLeftPosition) {
        case 0:
          num = 0;
          slideOption();
          break;

        case cardWrapWidth:
          num = 1;
          slideOption();
          break;
        
        case cardWrapWidth * 2:
          num = 2;
          slideOption();
          break;

        case cardWrapWidth * 3:
          num = 3;
          slideOption();
          break;

        case cardWrapWidth * 4:
          num = 4;
          slideOption();
          break;

        case cardWrapWidth * 5:
          num = 5;
          slideOption();
          break;

        case cardWrapWidth * 6:
          num = 6;
          slideOption();
          break;

        case cardWrapWidth * 7:
          num = 7;
          slideOption();
          break;

        case cardWrapWidth * 8:
          num = 8;
          slideOption();
          break;
      }

    });
  }


  function slideOption() {

    if (shareUserName) {
      // 所持画像配列からスライドで表示中の画像のurlを定義
      const imgUrlValue = imgUrl.value;
      const imgUrlArr = JSON.parse(imgUrlValue);
      const currentImgUrl = imgUrlArr[num];

      // 所持シーン名配列からスライドで表示中画像のシーン名を定義
      const sceneNamesValue = sceneNames.value;
      const sceneNamesArr = JSON.parse(sceneNamesValue);
      const currentScene = sceneNamesArr[num];

      // ログインユーザー名を取得
      const userNameValue = shareUserName.value;

      // 画像作成日配列からスライドで表示中画像の作成日を定義
      const imgDateValue = imgDate.value;
      const imgDateArr = JSON.parse(imgDateValue);
      const currentimgDate = imgDateArr[num];


      // スライド時に画像作成日を表示
      SlideimgDate.innerHTML = currentimgDate;
      
      // スライド時にダウンロード画像情報をセット
      imgDownload.setAttribute('href', currentImgUrl);
      imgDownload.download = `${userNameValue}の${currentScene}に対する価値観`;

      // スライド時にシェア画像のシーン名情報をセット
      const pageUrl = location.href;
      let shareHref = `https://twitter.com/share?url=${pageUrl}&hashtags=エンゲージメントカード,engagementcard,価値観,バリュー,心理的安全性を上げる,エンゲージメント,ワクワークする,${currentScene}に対する価値観&text=${userNameValue}の価値観`;
      slideSceneName1.setAttribute('href', shareHref);
      slideSceneName2.innerHTML = currentScene;
    }

  };

}
window.addEventListener("load", image_slide);