// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// require('bootstrap')
// require('../stylesheets/application')
import 'bootstrap';
import '../stylesheets/application';
require("../stage1")

if (window.matchMedia('(max-width: 767px)').matches) {
  //スマホ処理
  require("../sp_final_stage")
} else if (window.matchMedia('(min-width:768px)').matches) {
  //PC処理
  require("../final_stage")
}

require("../gnav")
require("../check_input")
require("../job_chart")
require("../image_slide")
require("../select_options")
require("../mypage_menu")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
