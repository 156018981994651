function sp_final_stage() {
  const bestImgBtns = document.querySelectorAll('.best-img-btn');

  const modalWrapper = document.querySelector('.modal-wrapper');
  const modalImage = document.querySelector('.modal-image');

  const parent = document.getElementById('god-select');
  const just7G = document.getElementById('just-seven-gods') != null;

  const over_alert = document.getElementById('over_gods');
  const short_alert = document.getElementById('short_gods');
  
  const sub = document.getElementById('submit');

  bestImgBtns.forEach(bib => {
    bib.addEventListener('click', () => {

      // モーダル表示
      modalWrapper.classList.add('show');
      modalImage.classList.add('show');

      // bibのsrcを取得してmodal-imageにセット
      let cardAdd = bib.getAttribute('src');
      modalImage.src = cardAdd;

      // bibのvalue(best.id)を取得してmodal-imageにセット
      const bestId = bib.getAttribute('value');
      modalImage.value = bestId;
      
      // hidden_fieldのvalueにフォームで送信するためのbestIdをセット
      const currentBest = document.getElementById('current-best');
      currentBest.value = bestId;

      // bestカードクリックでflashメッセージ非表示
      over_alert.style.display = "none";
      short_alert.style.display = "none";
    });
  });

  // モーダルのカード画像をクリック
  if (modalImage) {
    modalImage.addEventListener('click', () => {

      // godカードの画像submitボタンを生成するためのinputタグを生成
      let child = document.createElement('input');

      // modal-imageのsrcを変数に代入
      let modalCardAdd = modalImage.src

      // modal-imageのvalueを変数に代入
      const modalbestId = modalImage.getAttribute('value');

      // godカード７枚制限。elseでアラート表示
      let god_count = n + 1;
      if (god_count <= 7) {
        // クリックしたbestカードを非表示
        bestImgBtns.forEach(bib => {
          const bestId = bib.getAttribute('value');
          if (bestId == modalbestId) {
            bib.style.display = "none";
          }
        });
        // bestsから選んだカードからgodカードを生成(呼び出し)
        addGod(child, modalCardAdd, modalbestId);
      } else {
        over_alert.style.display = "block";
      }

      textAdd();
    });
  }

  // モーダルのカード画像以外の部分をクリックでモーダルを非表示
  if (modalWrapper) {
    modalWrapper.addEventListener('click', () => {
      if (modalWrapper.classList.contains('show')) {
        modalWrapper.classList.remove('show');
        modalImage.classList.remove('show');
      }
    });
  }

  let n = 0;

  // bestsから選んだカードからgodカードを生成
  function addGod(child, modalCardAdd, modalbestId) {
    n++;
    child.type = "image";
    child.src = modalCardAdd;
    child.value = modalbestId;
    child.alt = "myGod" + n;
    child.classList = "final-check-cardsize";
    child = parent.appendChild(child);

    child.addEventListener('click', () => {

      n--;

      // child(godの画像サブミット)のvalue: god.idをパースして取得
      const godId = JSON.parse(child.value);

      // hidden_fieldのvalueにフォームで送信するためのgodIdをセット
      const currentGod = document.getElementById('current-god');
      currentGod.value = godId;

      // クリックしたgodカードを非表示
      child.style.display = "none";

      // クリックしたgodカードをbestカードに表示する
      bestImgBtns.forEach(bib => {
        const bestId = bib.getAttribute('value');
        if (bestId == godId) {
          bib.style.display = "block";
        }
      });

      // godカードクリックでflashメッセージ非表示
      over_alert.style.display = "none";
      short_alert.style.display = "none";

      textRemove();
    });
  };

  // godカード７枚未満の場合に"画像を作成"を押すとflashメッセージ表示
  if (sub) {
    sub.addEventListener('click', (e) => {

      if (!just7G && n < 7) {
        short_alert.style.display = "block";
        e.preventDefault();
      }
    });
  }

  // テキスト関係の表示、非表示切り替え
  const bottomText = document.getElementById('bottom-select-p');

  function textAdd() {
    if (n >= 1) {
      bottomText.style.display = "block";
    }
  };

  function textRemove() {
    if (n == 0) {
      bottomText.style.display = "none";
    }
  };

}
window.addEventListener("load", sp_final_stage);