function stage1() {
  const chooseBtns = document.querySelectorAll('.button__text');

  const arrCard = document.querySelector('.arr-card');

  if (arrCard) {
    
    const arrCardValue = arrCard.value;
    const arr = JSON.parse(arrCardValue);

    const currentUserId = document.getElementById('current-user-id').value;

    let num = 0;

    chooseBtns.forEach(btn => {
      btn.addEventListener('click', () => {

        num++;

        const progressNum = document.getElementById('progress-num');
        const maintopText = document.getElementById('maintop-text');
        if (num < arr.length) {
          progressNum.innerHTML = `${num + 1} 枚目/全${arr.length}枚中`;
          maintopText.innerHTML = `あなたにとって「${arr[num]['attributes'].name}」は`;
        }
        
        // フォームのvalueにカードidをセット
        const cardId = arr[num - 1]['attributes'].id;
        const currentCard = document.getElementById("current-card");
        currentCard.value = cardId;
        // currentCard.setAttribute("value", cardId);

        // 最後のカード選択後、自動遷移(呼び出し)
        movePage();
      });
    });

    const bestBtn = document.getElementById('best-btn');
    const parent = document.getElementById('best-select');
    const enterBtn = document.getElementById('enterBtn');
    enterBtn.style.visibility = "hidden";

    let n = 0;
    bestBtn.addEventListener('click', () => {

      let child = document.createElement("img");

      const bestAdr = arr[num - 1]['attributes'].address;
      // 選択したBestカードをBottomに表示(呼び出し)
      addChild(child, bestAdr);

      // Best7枚以上で決定ボタン表示(呼び出し)
      addEnterBtn();
    });

    // 選択したBestカードをBottomに表示
    function addChild(child, bestAdr) {
      n++;
      child.src = bestAdr;
      child.classList = "check__cardsize";
      child.alt = "myBest" + n;
      child = parent.appendChild(child);
    };

    // Best7枚以上で決定ボタン表示
    function addEnterBtn() {
      if (n >= 7) {
        enterBtn.style.visibility = "visible";
      } else {
        enterBtn.style.visibility = "hidden";
      }
    };

    function movePage() {
      if (num >= arr.length) {
        // 最後のカード選択後、自動遷移
        location.href = `/users/${currentUserId}/edit`;
      } else {
        // 次のカード表示
        document.card.src = arr[num]['attributes'].address;
      }
    };

  }
}
window.addEventListener("load", stage1);